const TIMEOUT = 300;

export function onElementAvailableByTagName(
  tagName: string,
  rootRef: React.RefObject<HTMLDivElement>,
  onElementFound: (foundElements: HTMLCollectionOf<Element>) => void,
) {
  if (rootRef.current) {
    const elements = rootRef.current.getElementsByTagName(tagName);
    if (elements && elements.length) {
      onElementFound(elements);
      return;
    }
  }

  setTimeout(onElementAvailableByTagName, TIMEOUT, tagName, rootRef, onElementFound);
}
