/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import styled from 'styled-components';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';

import type { AssetType } from '../../../types';
import { parseAssetValue } from '../../utils/parseAssetValue';
import { Video } from './Video';

export interface StyledMediaWWrapperProps {
  isLandscapeSize?: boolean;
}

export const StyledMediaWWrapper = styled.div<StyledMediaWWrapperProps>`
  height: auto;

  ${(props) => {
    return `
      flex: ${props.isLandscapeSize ? '2' : '1'} 1 0;

      @media (min-width: calc(${props.theme.breakpoints.l}px + 1px)) {
        :nth-child(3n) {
          margin-left: var(${props.theme.responsive.spacing.xxxl});
        }
      }

      @media (max-width: ${props.theme.breakpoints.l}px) {
        margin-top: var(${props.theme.responsive?.spacing.xl});

        :first-child {
          margin-top: 0;
        }
      }
    `;
  }}
`;

export interface MediaProps {
  media: AssetType;
  isLandscapeSize?: boolean;
  position: 'left' | 'right';
}

const Media: React.FC<MediaProps> = (props) => {
  const { media, isLandscapeSize, position } = props;

  const isVideo = media.assetType === 'video';
  const uePropertyPath = position === 'left' ? 'media1Option_asset' : 'media2Option_asset';
  const ueLabel = position === 'left' ? '#1 Media (Left)' : '#2 Media (Right)';
  const hasPortraitUrl = Boolean(media.srcPortrait && media.srcPortrait.assetValue);

  return (
    <UeContainer propertyPath={uePropertyPath} label={ueLabel}>
      {(ueContainerProps) => (
        <StyledMediaWWrapper isLandscapeSize={isLandscapeSize} {...ueContainerProps}>
          <UeReference propertyPath={uePropertyPath} behavior="component">
            {hasPortraitUrl && (
              <UeElement
                type="media"
                property="src"
                label={`${ueLabel} ${isVideo ? 'Video' : 'Image'}`}
              >
                {isVideo ? (
                  <Video position={position} video={media} />
                ) : (
                  <>
                    <img
                      className="media-image screen-size-xs screen-size-s screen-size-m"
                      src={parseAssetValue(media.srcPortrait?.assetValue).src}
                      alt={media.alt}
                      loading="lazy"
                    />
                    <img
                      className="media-image screen-size-l screen-size-xl screen-size-xxl"
                      src={parseAssetValue(media.src.assetValue).src}
                      alt={media.alt}
                      loading="lazy"
                    />
                  </>
                )}
              </UeElement>
            )}
            {!hasPortraitUrl && (
              <UeElement
                type="media"
                property="src"
                label={`${ueLabel} ${isVideo ? 'Video' : 'Image'}`}
              >
                {isVideo ? (
                  <Video position={position} video={media} />
                ) : (
                  <img
                    className="media-image screen-size-xs screen-size-s screen-size-m screen-size-l screen-size-xl screen-size-xxl"
                    src={parseAssetValue(media.src.assetValue).src}
                    alt={media.alt}
                    loading="lazy"
                  />
                )}
              </UeElement>
            )}
          </UeReference>
        </StyledMediaWWrapper>
      )}
    </UeContainer>
  );
};

export default Media;
