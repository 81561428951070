import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { Text, Theme } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';

import { Context } from '../../Context';
import { Disclaimers } from './Disclaimers';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';

export interface StyledLegalWWrapperProps {
  isLandscapeSize?: boolean;
}

const StyledLegalInfo = styled.div<StyledLegalWWrapperProps>`
  height: auto;

  ${(props) => {
    return `
      flex: ${props.isLandscapeSize ? '2' : '1'} 1 0;
    `;
  }}

  ${({ theme }: { theme: Theme }) => {
    return `
      @media (min-width: calc(${theme.breakpoints.l}px + 1px)) {
        :nth-child(2n) {
              margin-left: var(${theme.responsive?.spacing.xxxl});
        }
      }
    `;
  }}

  ${({ theme }: { theme: Theme }) => {
    return `
      margin-top: var(${theme.responsive?.spacing.m});
      margin-bottom: var(${theme.responsive?.spacing.m});
    `;
  }}
`;

export interface LegalInfoProps {
  isLandscapeSize?: boolean;
  caption?: string;
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
  position: 'left' | 'right';
}

export const LegalInfo: React.FC<LegalInfoProps> = ({
  isLandscapeSize,
  caption,
  consumptionAndEmissions,
  disclaimers,
  position,
}) => {
  const { localeService, vueFormatterService } = useContext(Context);

  const ueProperty = position === 'left' ? 'media1Option_caption' : 'media2Option_caption';
  const ueLabel = position === 'left' ? '#1 Media (Left)' : '"#2 Media (Right)';

  return (
    <StyledLegalInfo
      className="legal-info"
      data-testid="legal-info-section"
      isLandscapeSize={isLandscapeSize}
    >
      {caption && (
        <Text variant="copy2" spaceStackEnd="xs">
          <UeElement type="text" property={ueProperty} label={`${ueLabel} Caption`}>
            {renderTextWithFootnotesReferencesV2(caption)}
          </UeElement>
        </Text>
      )}
      {vueFormatterService && localeService && (
        <ConsumptionAndEmissions
          caeIds={consumptionAndEmissions}
          vueFormatterService={vueFormatterService}
          localeService={localeService}
        />
      )}
      <Disclaimers disclaimers={disclaimers} />
    </StyledLegalInfo>
  );
};

LegalInfo.displayName = 'LegalInfo';
