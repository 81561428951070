/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled from 'styled-components';

export interface StyledEditorialMediaContainerProps {
  hasMarginTop?: boolean;
  hasMarginBottom?: boolean;
}

export const StyledEditorialMediaContainer = styled.div<StyledEditorialMediaContainerProps>`
  sup {
    // footnotes too small fix
    font-size: 9px !important;
    line-height: initial;
  }

  ${(props) => {
    return `
      background-color: var(${props.theme.colors.background.level[0]});

      padding-top: var(${props.hasMarginTop ? props.theme.responsive?.spacing.xxxl : 0});
      padding-bottom: var(${props.hasMarginBottom ? props.theme.responsive?.spacing.xl : 0});

      @media (min-width: ${props.theme.breakpoints.xs}px) {
        padding-left: var(${props.theme.responsive.spacing.pageMargin});
        padding-right: var(${props.theme.responsive.spacing.pageMargin});
      }

      @media (min-width: ${props.theme.breakpoints.l}px) {
        flex-direction: row;

        img {
          height: 100%;
        }
      }

      .media-image {
        width: 100%;
        object-fit: cover;
        display: none;

        &.screen-size-xs {
          @media only screen and (min-width: ${props.theme.breakpoints.xs}px) and (max-width: ${props.theme.breakpoints.s - 1}px) {
            display: block;
          }
        }

        &.screen-size-s {
          @media only screen and (min-width: ${props.theme.breakpoints.s}px) and (max-width: ${props.theme.breakpoints.m - 1}px) {
            display: block;
          }
        }

        &.screen-size-m {
          @media only screen and (min-width: ${props.theme.breakpoints.m}px) and (max-width: ${props.theme.breakpoints.l - 1}px) {
            display: block;
          }
        }

        &.screen-size-l {
          @media only screen and (min-width: ${props.theme.breakpoints.l}px) and (max-width: ${props.theme.breakpoints.xl - 1}px) {
            display: block;
          }
        }

        &.screen-size-xl {
          @media only screen and (min-width: ${props.theme.breakpoints.xl}px) and (max-width: ${props.theme.breakpoints.xxl - 1}px) {
            display: block;
          }
        }

        &.screen-size-xxl {
          @media only screen and (min-width: ${props.theme.breakpoints.xxl}px) {
            display: block;
          }
        }
      }
    `;
  }}
`;
