/* eslint-disable camelcase */

export interface AssetBrowserMediaItem {
  uuid: string;
  vmsId: number;
  fileInfo: {
    fileSource: string;
    fileSize: string;
    fileExtension: string;
    fileName: string;
  };
  createdAt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  updatedAt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  newLicences: [];
  tags: string[];
  title: string;
  prNumbers: [];
  contactPerson: string;
  description: string;
  usageTo?: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  usageFrom?: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  id: string;
}

export interface AssetBrowserImageItem extends AssetBrowserMediaItem {
  type: 'image';
  carSpecifications: {
    model: string;
    type: string;
    productionYear: string;
  };
  imageFormats: {
    thumb_270p: string;
    thumb_396p: string;
    thumb_500p: string;
    thumb_720p: string;
    thumb_1080p: string;
    'web-largest': string;
  };
}

export interface AssetBrowserVideoItem extends AssetBrowserMediaItem {
  type: 'video';
  imageFormats: {
    thumb_270p: string;
    thumb_396p: string;
    thumb_500p: string;
    thumb_720p: string;
    thumb_1080p: string;
    'web-largest': string;
  };
  sourceFormats: {
    content_270p: string;
    thumb_396p: string;
    content_500p: string;
    content_720p: string;
    content_1080p: string;
  };
  productionDate: string;
  videoType: string[];
  responsible: string[];
  videoContent: string[];
  regisseur: string[];
  contractsLicenses: string[];
  furtherDocuments: string[];
  ownerGroup: string[];
  videoLanguage: string[];
  productKey?: string;
  productionCompany?: string;
  team?: string;
  importKey?: string;
  externalKey?: string;
}

export interface ParsedAssetBrowserSelectionState {
  image?: AssetBrowserImageItem[];
  video?: AssetBrowserVideoItem[];
}

export interface ParsedAssetValue {
  src: string;
  posterSrc?: string;
}

export function parseAssetValue(assetValue?: string): ParsedAssetValue {
  const assetUrl = assetValue ? assetValue.trim() : '';
  let parsedAssetUrl: ParsedAssetBrowserSelectionState;

  if (!assetUrl.startsWith('{')) {
    // standard URL, no parsing
    return { src: assetUrl };
  }

  try {
    parsedAssetUrl = JSON.parse(assetUrl) as ParsedAssetBrowserSelectionState;
  } catch {
    // parsing failed
    throw new Error(`Error parsing JSON assetUrl (${assetUrl})`);
  }

  if (parsedAssetUrl.video) {
    // parse video url
    const { sourceFormats, imageFormats } = parsedAssetUrl.video[0];
    const parsedAssetValue: ParsedAssetValue = { src: sourceFormats.content_1080p };
    if (!parsedAssetValue.src) {
      throw new Error('content_1080p not found in sourceFormats');
    }
    if (imageFormats) {
      parsedAssetValue.posterSrc = imageFormats['web-largest'] || imageFormats.thumb_1080p;
    }
    return parsedAssetValue;
  }

  if (parsedAssetUrl.image) {
    // parse image url
    const { imageFormats } = parsedAssetUrl.image[0];
    const imageUrl = imageFormats['web-largest'];
    if (!imageUrl) {
      throw new Error('web-largest not found in imageFormats');
    }
    return { src: imageUrl };
  }

  return { src: '' };
}
