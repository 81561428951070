import { ContentServiceV1 } from '@oneaudi/content-service';
import {
  // Asset,
  // ContentReference,
  InvalidContentError,
  isContentFragment,
} from '@oneaudi/falcon-tools';
import {
  AppContent,
  AssetContentFragment,
  AssetType,
  AssetTypeVideo,
  EditorJsonContent,
  FalconContent,
} from '../../types';
import { debug } from './debugging';

const PLACEHOLDER_IMAGE_URL = `https://${__FEATURE_APP_NAME__}.cdn.prod.collab.apps.one.audi/${__FEATURE_APP_VERSION__}/static/img/placeholder-image.jpg`;

function isFalconContent(content: FalconContent): content is FalconContent {
  // Check here for all required fields and return true, otherwise false
  return Boolean(isContentFragment(content));
}

export function mapContent(contentService: ContentServiceV1): AppContent {
  const content = contentService.getContent() as EditorJsonContent | FalconContent;

  if (content.__type === 'aem-headless') {
    // Perform runtime validation
    if (!isFalconContent(content)) {
      // Throw `InvalidContentError` here if provided content
      // is not valid. This will display as a special error message
      // in the frontend on author environments.
      throw new InvalidContentError('Content is invalid.');
    }

    // map CF content to internal structure
    return mapFalconContent(content);
  }

  // map FACE content to internal structure
  return mapEditorJsonContent(content);
}

/**
 * Map EditorJsonContent to AppContent
 *
 * @param content EditorJsonContent
 * @returns AppContent
 */
export function mapEditorJsonContent(content: EditorJsonContent): AppContent {
  debug('mapEditorJsonContent before', content);

  const mappedContent: AppContent = {
    media1: content.media1Option.asset,
    theme: content.themeOption && content.themeOption.theme ? content.themeOption.theme : 'Light',
    hasMarginTop: content.featureAppMarginOption && content.featureAppMarginOption.marginTop,
    hasMarginBottom: content.featureAppMarginOption && content.featureAppMarginOption.marginBottom,
  };

  if (content.media1Option) {
    if (content.media1Option.caption) {
      mappedContent.caption1 = content.media1Option.caption;
    }
    if (content.media1Option.consumptionAndEmissions) {
      mappedContent.consumptionAndEmissions1 = content.media1Option.consumptionAndEmissions;
    }
    if (content.media1Option.disclaimers) {
      mappedContent.disclaimers1 = content.media1Option.disclaimers;
    }
  }

  if (
    content.media2Option &&
    content.media2Option.asset &&
    content.media2Option.asset.src &&
    content.media2Option.asset.src.assetValue
  ) {
    mappedContent.media2 = content.media2Option.asset;
    mappedContent.proportion = content.media2Option.proportion || '1-1 Same Size';

    if (content.media2Option.caption) {
      mappedContent.caption2 = content.media2Option.caption;
    }

    if (
      content.media2Option.consumptionAndEmissions &&
      content.media2Option.consumptionAndEmissions.length > 0
    ) {
      mappedContent.consumptionAndEmissions2 = content.media2Option.consumptionAndEmissions;
    }
    if (content.media2Option.disclaimers && content.media2Option.disclaimers.length > 0) {
      mappedContent.disclaimers2 = content.media2Option.disclaimers;
    }
  }

  debug('mapEditorJsonContent after', mappedContent);

  return mappedContent;
}

/**
 * Map FalconContent to AppContent
 *
 * @param content FalconContent
 * @returns AppContent
 */
export function mapFalconContent(content: FalconContent): AppContent {
  debug('mapFalconContent before', content);

  const mappedContent: AppContent = {
    media1: {
      assetType: 'image',
      alt: 'Placeholder Image',
      src: {
        assetValue: PLACEHOLDER_IMAGE_URL,
      },
    },
    theme: 'Light',
    hasMarginTop: true,
    hasMarginBottom: true,
  };

  mappedContent.media1 = addVideoSettings(
    content.fields.media1Option_asset,
    mappedContent.media1 as AssetTypeVideo,
  ) as AssetType;

  if (typeof content.fields.themeOption_theme !== 'undefined') {
    mappedContent.theme = content.fields.themeOption_theme;
  }

  if (typeof content.fields.featureAppMarginOption_marginTop !== 'undefined') {
    mappedContent.hasMarginTop = Boolean(content.fields.featureAppMarginOption_marginTop);
  }

  if (typeof content.fields.featureAppMarginOption_marginBottom !== 'undefined') {
    mappedContent.hasMarginBottom = Boolean(content.fields.featureAppMarginOption_marginBottom);
  }

  if (
    typeof content.fields.media1Option_asset !== 'undefined' &&
    typeof content.fields.media1Option_asset.fields !== 'undefined'
  ) {
    if (typeof content.fields.media1Option_asset.fields.assetType !== 'undefined') {
      mappedContent.media1.assetType = content.fields.media1Option_asset.fields.assetType;
    }
    if (typeof content.fields.media1Option_asset.fields.alt !== 'undefined') {
      mappedContent.media1.alt = content.fields.media1Option_asset.fields.alt;
    }
    if (
      typeof content.fields.media1Option_asset.fields.src !== 'undefined' &&
      typeof content.fields.media1Option_asset.fields.src.path !== 'undefined'
    ) {
      mappedContent.media1.src.assetValue = content.fields.media1Option_asset.fields.src.path;
    }
    if (
      typeof content.fields.media1Option_asset.fields.srcPortrait !== 'undefined' &&
      typeof content.fields.media1Option_asset.fields.srcPortrait.path !== 'undefined'
    ) {
      mappedContent.media1.srcPortrait = {
        assetValue: content.fields.media1Option_asset.fields.srcPortrait.path,
      };
    }
  }

  if (content.fields.media1Option_caption) {
    mappedContent.caption1 = content.fields.media1Option_caption;
  }

  if (
    content.fields.media1Option_consumptionAndEmissions &&
    content.fields.media1Option_consumptionAndEmissions.length > 0
  ) {
    mappedContent.consumptionAndEmissions1 = content.fields.media1Option_consumptionAndEmissions;
  }

  if (
    content.fields.media1Option_disclaimers &&
    content.fields.media1Option_disclaimers.length > 0
  ) {
    mappedContent.disclaimers1 = content.fields.media1Option_disclaimers;
  }

  if (
    content.fields.media2Option_asset &&
    content.fields.media2Option_asset.fields &&
    content.fields.media2Option_asset.fields.src &&
    content.fields.media2Option_asset.fields.src.path
  ) {
    mappedContent.media2 = {
      assetType: content.fields.media2Option_asset.fields.assetType,
      alt: content.fields.media2Option_asset.fields.alt,
      src: {
        assetValue: content.fields.media2Option_asset.fields.src.path,
      },
    };

    mappedContent.media2 = addVideoSettings(
      content.fields.media2Option_asset,
      mappedContent.media2 as AssetTypeVideo,
    );

    mappedContent.proportion = content.fields.media2Option_proportion || '1-1 Same Size';

    if (
      typeof content.fields.media2Option_asset.fields.srcPortrait !== 'undefined' &&
      typeof content.fields.media2Option_asset.fields.srcPortrait.path !== 'undefined'
    ) {
      mappedContent.media2!.srcPortrait = {
        assetValue: content.fields.media2Option_asset.fields.srcPortrait.path,
      };
    }

    if (content.fields.media2Option_caption) {
      mappedContent.caption2 = content.fields.media2Option_caption;
    }

    if (
      content.fields.media2Option_consumptionAndEmissions &&
      content.fields.media2Option_consumptionAndEmissions.length > 0
    ) {
      mappedContent.consumptionAndEmissions2 = content.fields.media2Option_consumptionAndEmissions;
    }
    if (
      content.fields.media2Option_disclaimers &&
      content.fields.media2Option_disclaimers.length > 0
    ) {
      mappedContent.disclaimers2 = content.fields.media2Option_disclaimers;
    }
  }

  debug('mapFalconContent after', mappedContent);

  return mappedContent;
}

function addVideoSettings(
  assetCF: AssetContentFragment,
  asset?: AssetTypeVideo,
): AssetType | undefined {
  if (asset && assetCF.fields.assetType === 'video') {
    return {
      ...asset,
      poster:
        assetCF.fields.poster && assetCF.fields.poster.path
          ? {
              assetValue: assetCF.fields.poster.path,
            }
          : undefined,
      autoPlay: Boolean(assetCF.fields.autoPlay),
      loop: Boolean(assetCF.fields.loop),
      muted: Boolean(assetCF.fields.muted),
      controls: Boolean(assetCF.fields.controls),
      preserveVideoWidth: Boolean(assetCF.fields.preserveVideoWidth),
    };
  }

  return asset;
}
